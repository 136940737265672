var outputTax = $('#cc_calculator #taxedcosts');
var output = $('#cc_calculator #costs');
var input = $('#cc_calculator #amount');
var timeoutId = 0;

function calculate(tax, element) {
    var amount = input.val();
    var result = 0;
    if (isNaN(amount)) {
        result = "";
    } else {
        // above 200000, costs are 0.5%
        if (amount > 200000) {
            result += ((amount - 200000) / 200);
            amount = 200000;
        }
        // above 10000, costs are 1%
        if (amount > 10000) {
            result += ((amount - 10000) / 100);
            amount = 10000
        }
        // above 5000, costs are 5%
        if (amount > 5000) {
            result += ((amount - 5000) / 20);
            amount = 5000;
        }
        // above 2500, costs are 10%
        if (amount > 2500) {
            result += ((amount - 2500) / 10);
            amount = 2500;
        }
        // below 2500, costs are 15%
        if (amount <= 2500) {
            result += ((amount / 100) * 15);
        }
        // minimum and maximum check
        result = (result < 48.40) ? 48.40 : result;
        result = (result > 8197.75) ? 8197.75 : result;
        result *= tax;
    }
    element.removeClass("calculating");
    if (!isNaN(amount)) {
        result = result.toFixed(2).replace('.', ',');
    }
    element.val(result);
}

input.keyup(function () {

    if (timeoutId != null) {
        clearTimeout(timeoutId);
        timeoutId = null;
    }

    outputTax.val('Berekenen...');
    output.val('Berekenen...');

    timeoutId = setTimeout(function() {
        calculate(1.21, outputTax);
        calculate(1, output);
    }, 500);
});






// /**
//  * This control needs the jQuery library
//  */

// function CollectionCostsCalculator() {
// }
// CollectionCostsCalculator.prototype = {
//     SLEEP_TIME: 500,
//     POPUP_TIME: 400,
//     INPUT_FIELD: jQuery('#cc_calculator #amount'),
//     OUTPUT_FIELD: jQuery('#cc_calculator #taxedcosts'),
//     COSTS_FIELD: jQuery('#cc_calculator #costs'),
//     BTW_FIELD: jQuery('#cc_calculator #tax'),
//     ICON_INFO: jQuery('#cc_calculator .cc_info_icon'),
//     ICON_CLOSE: jQuery('#cc_calculator .cc_close_icon'),
//     POPUP_DIV: jQuery('#cc_calculator .popup'),
//     _timeoutId: null,
//     init: function () {
//         this._inputEventListener();
//         this._iconEventListener();
//     },
//     _inputEventListener: function () {
//         this.INPUT_FIELD.keyup(jQuery.proxy(function (event) {
//             this.BTW_FIELD.val("Berekenen...");
//             this.BTW_FIELD.addClass("calculating");
//             this.OUTPUT_FIELD.val("Berekenen...");
//             this.OUTPUT_FIELD.addClass("calculating");
//             if (this._timeoutId != null) {
//                 clearTimeout(this._timeoutId);
//                 this._timeoutId = null;
//             }
//             this._timeoutId = setTimeout(jQuery.proxy(function () {
//                 this._inputChangeHandler();
//             }, this), this.SLEEP_TIME);


//         }, this));
//     },
//     _inputChangeHandler: function () {
//         this.OUTPUT_FIELD.val(this._calculateCosts());
//         this.BTW_FIELD.removeClass("calculating");
//         var btw = this._toFloat(this.OUTPUT_FIELD.val()) - this._toFloat(this.COSTS_FIELD.val());
//         if (isNaN(btw)) {
//             btw = "Vul bedrag in.";
//         } else {
//             btw = this._toCurrency(btw);
//         }

//         this.BTW_FIELD.val(btw);
//     },
//     _calculateCosts: function () {
//         var amount = this._toFloat(this.INPUT_FIELD.val());
//         var result = null;
//         if (isNaN(amount)) {
//             result = "Vul bedrag in.";
//         } else {
// // above 200000, costs are 0.5%
//             if (amount > 200000) {
//                 result += ((amount - 200000) / 200 * 1.21);
//                 amount = 200000;
//             }
// // above 10000, costs are 1%
//             if (amount > 10000) {
//                 result += ((amount - 10000) / 100 * 1.21);
//                 amount = 10000;
//             }
// // above 5000, costs are 5%
//             if (amount > 5000) {
//                 result += ((amount - 5000) / 20 * 1.21);
//                 amount = 5000;
//             }
// // above 2500, costs are 1%
//             if (amount > 2500) {
//                 result += ((amount - 2500) / 10 * 1.21);
//                 amount = 2500;
//             }
// // below 2500, costs are 15%
//             if (amount <= 2500) {
//                 result += ((amount / 100) * 15 * 1.21);
//             }
// // minimum and maximum check
//             result = (result < 48.40) ? 48.40 : result;
//             result = (result > 8197.75) ? 8197.75 : result;
//         }
//         this.OUTPUT_FIELD.removeClass("calculating");
//         return this._toCurrency(result);
//     },
//     _toFloat: function (input) {
//         return parseFloat(input.replace(/,/g, '.'));
//     },
//     _toCurrency: function (input) {
//         if (!isNaN(input)) {
// // taken from http://stackoverflow.com/questions/246193/how-do-i-round-a-number-in-javascript
// // var rounded = Math.round(input * 100) / 100;
//             var rst = (input * 100) / 100
//             var rounded = rst.toFixed(2);
//             return (rounded + '').replace('.', ',');
//         } else {
//             return input;
//         }
//     },
//     _iconEventListener: function () {
//         this.ICON_INFO.mouseover(
//                 jQuery.proxy(function () {
//                     this._infoIconHoverInHandler();
//                 }, this));
//         this.ICON_CLOSE.click(
//                 jQuery.proxy(function () {
//                     this._closeIconClickHandler();
//                 }, this));
//     },
//     _infoIconHoverInHandler: function () {
//         this.POPUP_DIV.fadeIn(this.POPUP_TIME);
//     },
//     _closeIconClickHandler: function () {
//         this.POPUP_DIV.fadeOut(this.POPUP_TIME);
//     }
// };
// var cc_calculator = new CollectionCostsCalculator();
// cc_calculator.init(); 